import SuperJSON from 'superjson';

const 블로그 = {
  '/blog': {
    globalAppBar: {
      type: 'title-left',
      text: '블로그',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/blog/more': {
    globalAppBar: {
      isFixed: true,
      leftItem: 'Back',
      type: 'title-center',
      text: '운전백과',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/blog/[id]': {
    globalAppBar: {
      isFixed: true,
      leftItem: 'Back',
      type: 'title-center',
      showProgressOnScroll: true,
      showWebToAppBanner: true,
      centerItem: 'TextLogo',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
} as const;

const 필기 = {
  '/test': {
    globalAppBar: {
      offNavbar: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
    bgColor: '#F6F6F3',
  },
  '/test/event': {
    globalAppBar: {
      leftItem: 'Back',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/test/speed-quiz': {
    globalAppBar: {
      offNavbar: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/test/today-problem': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '오늘의 문제',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
};

const 프로필 = {
  '/profile': {
    globalAppBar: {
      text: '내 정보',
      type: 'title-left',
      isFixed: true,
      rightItems: ['ProfileSetting', 'ProfileNotification'],
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/editInfo': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '내 정보 수정',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/myInfo': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '내 정보 관리',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/setting': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '설정',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/notification': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '푸시알림 설정',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/storage/coupon': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '쿠폰함',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/storage/reward': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '리워드',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/event': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '내 이벤트',
      backgroundColor: 'transparent',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/history': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '결제 내역',
      backgroundColor: '#FBFBF8',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/bookmark': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '북마크',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/editProfile/phoneNum': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '휴대폰 번호 변경',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/editProfile/nickname': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '닉네임 변경',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/order-detail': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '상세 내역',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {
      orderId: '' as string,
    },
    approachCase: {
      상세내역클릭: ({ orderId }: { orderId: string }) => ({
        orderId,
      }),
    },
  },
  '/profile/review': {
    globalAppBar: {
      leftItem: 'Close',
      type: 'title-center',
      text: '후기 작성',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/no-user-review': {
    globalAppBar: {
      type: 'title-center',
      text: '후기 작성',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/training': {
    globalAppBar: {
      type: 'title-center',
      text: '현황 관리',
      leftItem: 'Back',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/training/report': {
    globalAppBar: {
      type: 'title-center',
      text: '연수 분석 리포트',
      leftItem: 'Back',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/profile/training/report/detail': {
    globalAppBar: {
      type: 'title-center',
      text: '',
      leftItem: 'Back',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
};

const 이벤트 = {
  '/event/together': {
    globalAppBar: {
      leftItem: '',
      type: 'title-center',
      text: '',
      rightItems: [],
      offNavbar: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {
      'explore-search': ({
        academyId,
        lat,
        lng,
        address,
      }: {
        academyId: string;
        lat: number;
        lng: number;
        address: string;
      }) => ({
        academyId,
        lat,
        lng,
        address,
        currentScreen: SuperJSON.stringify('explore'),
      }),
    },
  },
  '/event/together/invite': {
    globalAppBar: {
      leftItem: '',
      type: 'title-center',
      text: '',
      rightItems: [],
      offNavbar: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/event/together/room/[id]': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '',
      rightItems: ['RoomSetting'],
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {
      'together-select-complete': {},
    },
  },
  '/event/together/no-room': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/event/lowest': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '최저가 보상제',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/event/winter': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '겨울방학 이벤트',
      rightItems: [],
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/why-drivingteacher': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '운전학원 온라인 예약',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  // '/why-drivingteacher/naver': {
  //   globalAppBar: {
  //     leftItem: '',
  //     type: 'title-center',
  //     text: '운전학원 온라인 예약',
  //     isFixed: true,
  //   },
  //   meta: {
  //     title: '',
  //     description: '',
  //     keywords: '',
  //     'og-image': '',
  //   },
  //   queryFlag: {},
  //   approachCase: {},
  // },
  '/event/roulette/[id]': {
    globalAppBar: {
      leftItem: 'WhiteBack',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/roulette/[id]': {
    globalAppBar: {
      leftItem: 'Back',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/roulette/result/[id]': {
    globalAppBar: {
      leftItem: 'Back',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/why-simulation': {
    globalAppBar: {
      text: '실내연습장 알아보기',
    },
    meta: { title: '', description: '', keywords: '', 'og-image': '' },
    queryFlag: {},
    approachCase: {},
  },

  '/event/go3-chicken': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '고3 학교대항전',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/event/go3-chicken/vote': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '고3 학교대항전',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/event/go3-chicken/need-marketing-agree': {
    globalAppBar: {
      leftItem: '',
      type: 'title-center',
      text: '',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/event/go3-chicken/login': {
    globalAppBar: {
      leftItem: '',
      type: 'title-center',
      text: '',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },

  '/event/first-come': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '선착순 할인 이벤트',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {
      univReferralUid: '',
    },
    approachCase: {},
  },
  '/event/camp': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '면허 캠프',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {
      univReferralUid: '',
    },
    approachCase: {},
  },
  '/event/camp/[id]': {
    globalAppBar: {
      isFixed: true,
      leftItem: 'Back',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
};
const 약관 = {
  '/terms/[id]': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '운전선생 이용약관',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },

  '/terms/refund': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '취소 규정 확인',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/terms/notification': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '광고성 정보 이용약관',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/withdrawal': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '회원탈퇴',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/terms': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '약관 및 정책',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
};

/**
 * 구매 여정을 구분하는 Flag
 */
export type ApplicationMode =
  | // 학원상세 - 혼자예약
  'alone-academy'
  // 탐색 - 혼자예약
  | 'alone-explore'
  // 수업상세 - 혼자예약
  | 'alone-lesson'
  // 같이취득 - 날짜선택
  | 'together-select'
  // 같이취득 - 결제
  | 'together-purchase'
  // 외부접속 - 학원 홈페이지 결제
  | 'external-academy-link'
  // 외부접속 - 현장결제
  | 'external-onsite';

const 구매 = {
  '/explore': {
    globalAppBar: {
      offNavbar: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/explore/recommendation': {
    globalAppBar: {
      leftItem: 'TextLogo',
      type: 'title-center',
      text: '',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/academy/[id]': {
    globalAppBar: {
      offNavbar: true,
    },
    backRedirectUrl: '/explore',
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/academy/[id]/application': {
    globalAppBar: {
      offNavbar: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {
      applicationMode: 'alone-academy' as ApplicationMode,
      isVerified: false,
      code: '' as LessonCode | '',
      periodText: '',
      registType: '' as RegistType | '',
      selectedTime: '',
      selectedRange: '',
    },
    approachCase: {
      'alone-academy': {
        applicationMode: 'alone-academy',
      },
      'alone-explore': {
        applicationMode: 'alone-explore',
      },
      bookmark: ({
        code,
        periodText,
        registType,
      }: {
        code?: LessonCode;
        periodText?: string;
        registType?: RegistType;
      }) => ({
        applicationMode: 'bookmark',
        code,
        periodText,
        registType,
        currentScreen: SuperJSON.stringify('LessonsSelect'),
      }),
      'alone-lesson': ({
        code,
        periodText,
        registType,
      }: {
        code?: LessonCode;
        periodText?: string;
        registType?: RegistType;
      }) => ({
        applicationMode: 'alone-lesson',
        code,
        periodText,
        registType,
        currentScreen: SuperJSON.stringify('DateSelect'),
      }),
      'together-select': {
        applicationMode: 'together-select',
      },
      'together-purchase': ({
        selectedTime,
        selectedRange,
        code,
        periodText,
        registType,
      }: {
        selectedTime?: SelectedTime;
        selectedRange?: SelectedRange;
        code: LessonCode | '';
        periodText: string;
        registType: RegistType | '';
      }) => ({
        applicationMode: 'together-purchase',
        code,
        periodText,
        registType,
        selectedRange: SuperJSON.stringify(selectedRange),
        selectedTime: SuperJSON.stringify(selectedTime),
      }),
    },
    backRedirectUrl: '/academy/[id]',
  },
  '/academy/[id]/lesson': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    backRedirectUrl: '/academy/[id]',
    queryFlag: {},
    approachCase: {},
  },
  '/academy/[id]/review': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '후기',
      isFixed: true,
    },
    backRedirectUrl: '/academy/[id]',
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/academy/[id]/course': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '시험코스 정보',
      isFixed: true,
    },
    backRedirectUrl: '/academy/[id]',
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
};

const 로그인 = {
  '/login': {
    globalAppBar: {
      leftItem: 'Back',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {
      univReferralUid: '',
    },
    approachCase: {
      대학교이벤트추천인: ({ univReferralUid }: { univReferralUid: string }) => ({
        univReferralUid,
      }),
    },
  },
  '/login/email': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
};

const 홈 = {
  '/': {
    globalAppBar: {
      isFixed: true,
      leftItem: 'TextLogo',
      rightItems: ['Coupon', 'ProfileNotification'],
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/guide/driver-test': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '기능시험 가이드',
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/training': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '방문 운전연수 신청',
      showProgressOnScroll: false,
      isFixed: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/training/reservation': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '방문 운전연수 신청',
      offNavbar: true,
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/training/remaining': {
    globalAppBar: {
      leftItem: 'Back',
      type: 'title-center',
      text: '방문 운전연수',
    },
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
  '/game': {
    globalAppBar: {
      offNavbar: true,
    },
    bgColor: '#F3F1FE',
    meta: {
      title: '',
      description: '',
      keywords: '',
      'og-image': '',
    },
    queryFlag: {},
    approachCase: {},
  },
};

export const PAGE_MAPPING = {
  ...로그인,
  ...블로그,
  ...필기,
  ...프로필,
  ...이벤트,
  ...약관,
  ...구매,
  ...홈,
} as const;

export const PAGE_GROUP = {
  홈: {
    ...홈,
  },
  로그인: {
    ...로그인,
  },
  블로그: {
    ...블로그,
  },
  필기: {
    ...필기,
  },
  프로필: {
    ...프로필,
  },
  이벤트: {
    ...이벤트,
  },
  약관: {
    ...약관,
  },
  구매: {
    ...구매,
  },
} as const;
